import React, {useEffect, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import "./EventDetail.css";
import Calender from "../../../assets/images/calendar.svg";
import Location from "../../../assets/images/orange-map-pin.svg";
import HomeBG from "../../../assets/images/event-banner.jpg";
import EventImage from "../../../assets/images/event-detail-img.png";
import eventsDetails from "../../Apis/eventsDetailsApi";
import getBanner from "../../Apis/bannerApi";
import {storagePath} from "../../../assets/utils/soragePath";
import getAssociates from "../../Apis/associatesApi";
import Associates from "../../Components/Assiciates/Associates";
import axiosInstance from "../../../assets/utils/api";
import getGamesData from "../../Apis/gameSchedule";
import {FaCircle, FaTrophy} from "react-icons/fa6";
import {IconButton} from "@mui/material";
import {LocationOn} from "@mui/icons-material";

const EventDetails = ({tid}) => {
    const [eventDetail, setEventDetail] = useState([]);
    const [games, setGames] = useState([]);
    const [imageBG, setImageBG] = useState(HomeBG);
    const [cowndownData, setCowndownData] = useState({});
    const [associatesLogos, setAssociatesLogos] = useState([]);
    const [eventStartCountdown, setStartCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const [registrationEndCountdown, setRegistrationCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const location = useLocation();
    const lastSegment = location.pathname.split("/").filter(Boolean).pop();
    const eventName = lastSegment.replaceAll("-", " ");
    const {eventpath} = useParams();
    const handleBanner = async () => {
        try {
            const response = await getBanner(eventpath);
            const url = storagePath + response[0].imgurl;
            setImageBG(url);
        } catch (e) {
            console.error(e);
        }
    }
    const fetchAssocieates = async () => {
        try {
            const response = await getAssociates(12);
            response.map((item) => {
                if (item.logo != "") {
                    associatesLogos.push(item.logo)
                }
            })
        } catch (e) {
            console.error('Error: ', e);
        }
    }
    const fetchGames = async () => {
        try {
            const response = await getGamesData(eventpath);
            setGames(response);
        } catch (e) {
            console.error('Error: ', e);
        }
    }
    const fetchEventDetail = async () => {
        try {
            const response = await eventsDetails(eventName);
            const dateData = await axiosInstance.get('/api/Countdown', {
                headers: {
                    'access-token': 'sp=65442Pre1+sdfcf'
                }
            })
            setCowndownData(dateData.data[0])
            const selectedEvent = response[0];
            setEventDetail(selectedEvent);
        } catch (error) {
            console.error("Error fetching event details:", error);
        }
    };
    useEffect(() => {
        handleBanner();
        fetchEventDetail();
        fetchAssocieates();
        fetchGames();
        setTimeout(() => {
            document.location.href = '#schaduled-games'
        }, 1000)
    }, []);
    useEffect(() => {
        const calculateCountdown = () => {
            if (!eventDetail) return;
            const targetDate = new Date(eventDetail.eventstartdate).getTime();
            const now = new Date().getTime();
            const difference = targetDate - now;
            if (difference < 0) {
                setStartCountdown({days: 0, hours: 0, minutes: 0, seconds: 0});
                return;
            }
            setStartCountdown({
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor(
                    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                ),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            });
            const targetDate2 = new Date(cowndownData?.date).getTime();
            const now2 = new Date().getTime();
            const difference2 = targetDate2 - now2;
            if (difference2 < 0) {
                setRegistrationCountdown({days: 0, hours: 0, minutes: 0, seconds: 0});
                return;
            }
            setRegistrationCountdown({
                days: Math.floor(difference2 / (1000 * 60 * 60 * 24)) + 1,
                hours: Math.floor(
                    (difference2 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                ),
                minutes: Math.floor((difference2 % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference2 % (1000 * 60)) / 1000),
            });
        };
        const timer = setInterval(calculateCountdown, 1000);
        return () => clearInterval(timer);
    }, [eventDetail]);

    function formatDate(dateString) {
        const date = new Date(dateString);

        const day = date.getDate();
        const month = date.toLocaleString("default", {month: "long"});
        const year = date.getFullYear();

        const daySuffix = (day) => {
            if (day > 3 && day < 21) return "th";
            switch (day % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        };

        return `${day}${daySuffix(day)} ${month} ${year}`;
    }

    function dateFormater(inputDateStr) {
        const [datePart, timePart] = inputDateStr.split(' ');
        const [day, month, year] = datePart.split('/').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        const date = new Date(year, month - 1, day, hours, minutes, seconds);
        const formattedDate = date.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
        return formattedDate;
    }

    return (
        <>
            <div className="inner-banner large">
                <figure>
                    <img
                        key={"banner"}
                        src={imageBG}
                        alt="Event Banner"
                    />
                </figure>
                <div className="banner-content">
                    <div className="inner-banner-txt">
                        <h1>{eventDetail?.eventname}</h1>
                        <div className="meta-data mt-2">
                            <ul>
                                <li>
                                    <img src={Calender} alt="Calendar"/>
                                    <span>{eventDetail.eventstartdate}</span>
                                </li>
                                <li>
                                    <img src={Location} alt="Location"/>
                                    <span>{eventDetail?.address}</span>
                                </li>
                            </ul>
                        </div>
                        <a
                            href={`${eventDetail.buttonurl}${tid}`}
                            className="action-button mt-3 mt-md-5">
                            <span></span>
                            <i>{eventDetail?.buttontext}</i>
                        </a>
                    </div>
                </div>
            </div>

            <section className="counter-section">
                <div className="container">
                    <div className="counter-wrap">
                        <h2>{cowndownData?.title}</h2>
                        <div className="live-clock">
                            <ul>
                                <li>
                                    <b>{registrationEndCountdown.days}</b>
                                    <span>days</span>
                                </li>
                                <li>
                                    <b>{registrationEndCountdown.hours}</b>
                                    <span>hours</span>
                                </li>
                                <li>
                                    <b>{registrationEndCountdown.minutes}</b>
                                    <span>minutes</span>
                                </li>
                                <li>
                                    <b>{registrationEndCountdown.seconds}</b>
                                    <span>seconds</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about section" style={{animationDelay: "200ms"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6" style={{animationDelay: "2s"}}>
                            <h1 className="mb-4 lh-base titleFont title">
                <span style={{color: "#0AA555"}}>
                  Join Us:
                </span>{" "}
                                <br/>
                                {eventDetail.eventname}
                            </h1>
                            <p>
                                We are excited to announce the {eventDetail.eventname}, a
                                historic event celebrating culture and tradition like never
                                before!
                            </p>
                            <p>
                                This is the first-ever cultural games event in the world, and we
                                are proud to host it at:
                            </p>
                            <div className="location-detail mb-3">
                                <table>
                                    <tr>
                                        <td className="fw-bold">Location:</td>
                                        <td className="fw-bold">
                                            {eventDetail.address}, {eventDetail.location}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Event Start Date:</td>
                                        <td className="fw-bold">
                                            {formatDate(eventDetail.eventstartdate)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="fw-bold">Event Close Date:</td>
                                        <td className="fw-bold">
                                            {formatDate(eventDetail.eventenddate)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Registration Open:</td>
                                        <td className="fw-bold">
                                            {formatDate(eventDetail.regstartdate)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">Registration Closes:</td>
                                        <td className="fw-bold">
                                            {formatDate(eventDetail.regenddate)}
                                        </td>
                                    </tr>
                                </table>
                                <p className="mb-0">
                                    <b>Calling All Artists and Players!</b>
                                </p>
                                <a
                                    href={`${eventDetail.buttonurl}${tid}`}
                                    className="action-button mt-3 mt-md-5"
                                >
                                    <span></span>
                                    <i>{eventDetail.buttontext}</i>
                                </a>
                                <p className="mb-0">
                                    Whether you are an artist or a player, you can register in
                                    your field of participation if it is mentioned in our list.
                                    Don’t miss this chance to showcase your talent on a national
                                    platform.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 text-center">
                            <div className="about-img">
                                <div className="about-card2">
                                    <img className="w-100" src={EventImage} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container' id='schaduled-games'>
                <div className="inner-container-small text-start mb-3 mb-md-5">
                    <h2 className="text-center mb-12 mb-lg-5 lower-text">Games Schedule</h2>
                    <h1 className="large-text text-center upper-text">Games Schedule</h1>
                </div>
                <div className='row'>
                    {games.map((item, index) => (
                        item?.schedule.length != 0 &&
                        <div className='col-12 col-md-6 col-xl-4 p-3 game-box'>
                            <div>
                                <h4><FaCircle fontSize={'10px'}/>{item?.game}</h4>
                                {
                                    item?.schedule?.map((schadule, index) => (
                                        <div>
                                            <p><strong>{schadule?.For}</strong></p>
                                            <p>From: <span>{dateFormater(schadule?.From)}</span></p>
                                            <p>To: <span>{dateFormater(schadule?.To)}</span></p>
                                            {schadule?.Address &&
                                                <a target='_blank' href={schadule?.Latlong} style={{
                                                    textAlign: 'start',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px'
                                                }}>
                                                    {/*<IconButton>*/}
                                                    <LocationOn/>
                                                    {/*</IconButton>*/}
                                                    <span>{schadule?.Address}</span>
                                                </a>}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className='container'>
                <div className="inner-container-small text-start my-3 my-md-5">
                    <h2 className="text-center mb-12 mb-lg-5 lower-text">Our Associates</h2>
                    <h1 className="large-text text-center upper-text">Associates</h1>
                </div>
                {
                    <Associates logos={associatesLogos}/>
                }
            </section>

            {/*<section className="counter-section">
              <div className="container">
                <div className="counter-wrap">
                  <h2>Count Every Second Until the Games</h2>
                  <div className="live-clock">
                    <ul>
                      <li>
                        <b>{eventStartCountdown.days}</b>
                        <span>days</span>
                      </li>
                      <li>
                        <b>{eventStartCountdown.hours}</b>
                        <span>hours</span>
                      </li>
                      <li>
                        <b>{eventStartCountdown.minutes}</b>
                        <span>minutes</span>
                      </li>
                      <li>
                        <b>{eventStartCountdown.seconds}</b>
                        <span>seconds</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>*/}

            <section className="section">
                <div className="container">
                    <h1 className="mb-4 lh-base titleFont title text-center">
                        Event Related Categories
                    </h1>
                    <div className="row">
                        {eventDetail?.relatedcategories?.map((item, index) => (
                            <div key={index} className="eventCategoryList">
                                <h3>{item.category}</h3>
                                <ul>
                                    {
                                        item?.games.map((item1) => (
                                            <li>{item1.game}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default EventDetails;
