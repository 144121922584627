import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useLocation} from 'react-router-dom';

function MetaData() {
  const {pathname} = useLocation();
  const [metaData, setMetaData] = useState({
    title: 'Pythian Games',
    description: 'National Pythian Games 2024',
    keyword: 'Pythian, Games',
  });

  useEffect(() => {
    // var path = pathname.replace('/', '');

    fetch('/seo.json')
      .then((response) => response.json())
      .then((data) => {
        let pathMetaData;
        if (pathname === '/') {
          pathMetaData = data["home"] || metaData;
        } else {
          pathMetaData = data[pathname.replace('/', '')] || metaData;
        }
        setMetaData(pathMetaData);
      })
      .catch((error) => {
        console.error('Error loading metadata:', error);
      });
  }, [pathname]);

  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description}/>
      <meta name="keywords" content={metaData.keyword}/>
    </Helmet>
  );
}

export default MetaData;
