import React from 'react';
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import QRcode from '../../../assets/images/qr-code.png';
import pdf1 from '../../../assets/images/doc/Approval-Letter-for-form-CSR1.pdf';
import pdf2 from '../../../assets/images/doc/Delphic-India-Trust-12A-Registration.pdf';
import './Donation.css';
import {donationPath} from "../../../assets/utils/donationPath"; // Your custom CSS

const Donation = () => {
  return (
    <>
      <InnerBanner pageName="Donation"/>
      <section className="post section">
        <div className="container">
          <h2 className="text-center lh-base titleFont title">Donate Now</h2>
          <p className="mb-5 text-center">
            Alone we can do so little, together we can do so much
          </p>
          <div className='row custom-box-shadow donate-section'>
            <div className="col-12 col-lg-6 text-center qr">
              <img src={QRcode} alt="QR Code" className="scan"/>
            </div>
            <div className="col-12 col-lg-6 details mt-4 text-start">
              <div>
                <dl className="row">
                  <dt className="col-sm-5 col-12">Bank Name</dt>
                  <dd className="col-sm-7 col-12">UCO Bank</dd>

                  <dt className="col-sm-5 col-12">Branch Name</dt>
                  <dd className="col-sm-7 col-12">South Patel Nagar, New Delhi</dd>

                  <dt className="col-sm-5 col-12">Account Number</dt>
                  <dd className="col-sm-7 col-12">10210210003114</dd>

                  <dt className="col-sm-5 col-12">Account Type</dt>
                  <dd className="col-sm-7 col-12">Current Account</dd>

                  <dt className="col-sm-5 col-12">IFSC Code</dt>
                  <dd className="col-sm-7 col-12">UCBA0001021</dd>
                </dl>
                <button onClick={() => window.location.href = donationPath} className="w-100 register mt-4">
                  Donate Now
                </button>
              </div>
            </div>
          </div>
          <div className="links">
            <ul>
              <li className="mb-2">
                <a
                  href={pdf1}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Eligible for CSR
                </a>
              </li>
              <li className="mb-2">
                <a
                  href={pdf2}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Registered under 12A and 80G
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Donation;
