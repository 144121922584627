import axiosInstance from "../../assets/utils/api";


const getGamesData = async (pageName) => {
    try {
        const response = await axiosInstance.get(`/api/GameSchedule`, {
            headers: {
                "Access-Token": "PggameSCHedule20@24",
                "pagename":pageName
            }
        })
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default getGamesData;