import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/styles.css'
import {useEffect, useState} from "react";
import axiosInstance from "../../../assets/utils/api";

const ImpNotification = () => {
    const [data, setData] = useState({});
    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await axiosInstance.get('/api/ImpNotification', {
                    headers: {
                        'pagename': 'Home',
                        'access-token': 'PpoPuGp2@12Notifcation',
                        'eventid': 1
                    }
                })
                setData(response.data[0])
                document.getElementById('notify-heading').innerHTML = response.data[0].headingtext || ''
                document.getElementById('notify-description').innerHTML = response.data[0].headingtextdescription.replaceAll('\r\n', '<br/>') || ''
            } catch (e) {
                console.error(e)
            }
        }
        fetchDetails()
    }, []);

    return (
        <>
            <InnerBanner pageName="Important Notification"/>
            <section className="about-us section">
                <div className="container notification-box">
                    <h2 className="mb-4 titleFont title" id='notify-heading'></h2>
                    <p id='notify-description'></p>
                </div>
            </section>
        </>
    )
}
export default ImpNotification;