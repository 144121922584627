import axiosInstance from "../../assets/utils/api";


const getVideos = async () => {
  try {
    const response = await axiosInstance.get(`/api/EventsDetVideos`, {
      headers: {
        "Access-Token": "VentthoeCr3*PrvROz_9roUp"
      }
    })
    return response.data;
  } catch (error) {
    throw error;
  }
};
export default getVideos;