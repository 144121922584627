import React from 'react';
import './Marquee.css';
import {storagePath} from "../../../assets/utils/soragePath";

const Associates = ({logos}) => {
    return (
        <div className="marquee-container">
            <div className="marquee-content">
                {logos.concat(logos).map((logo, index) => (
                    <img key={index} src={storagePath + logo} alt={`Logo ${index}`} className="logo"/>
                ))}
            </div>
        </div>
    );
};

export default Associates;