import './Banner.css'
import {useEffect, useState} from "react";
import getBanner from "../../Apis/bannerApi";
import {Link, useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import {Carousel} from 'react-bootstrap';
import {storagePath} from "../../../assets/utils/soragePath";

const Banner = () => {

    const location = useLocation();
    const [bannerData, setBannerData] = useState({})

    const handleBanner = async () => {
        let path = location.pathname.replace(/^\//, '');
        try {
            const response = await getBanner(path === '' || path === null ? 'home' : path);
            setBannerData(response);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        handleBanner();
    }, [location])

    return (
        <Carousel className="banner-carousel">
            {
                Object.keys(bannerData).map((key, index) => (
                    <Carousel.Item className='banner'>
                        <img className='home-banner-image'
                             src={`${storagePath}${bannerData[key].imgurl}`}
                             alt={bannerData[key].alttext}/>
                        <Carousel.Caption className="home-banner-content">
                            <h1>{bannerData[key].headingtext}</h1>
                            <Link to={bannerData[key].buttonurl}
                                  className="action-button"><span></span><i>{bannerData[key].buttontext}</i></Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))
            }
        </Carousel>
    )
}
export default Banner