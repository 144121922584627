import axiosInstance from '../../assets/utils/api';

const getCouncilData = async (slug) => {
    try {
        const response = await axiosInstance.get(
            `/api/OurCouncil`,
            {
                headers: {
                    "access-token": "W#e&RePR5+A112",
                    "pagename": "our-council",
                    "slug": slug
                }
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default getCouncilData;