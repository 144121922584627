const vidData = {
    "historicalData": [
        {
            "title": "Delphi Economic Forum",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/poster1.jpg",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/Delphi-Economic-Forum.mp4"
        },
        {
            "title": "DD promotion",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/dd-poster.png",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/DDPromo.mp4"
        },
        {
            "title": "Krishna and Pythian Games",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/poster-krishna.png",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/Krishna-and-Pythian-Games.mp4"
        },
        {
            "title": "Greek Embassay",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/greek.png",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/Greek-Embassay.mp4"
        },
        {
            "title": "Pythian Games Festival 2023",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/pythian.png",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/Pythian-Games-Festival-2023.mp4"
        }
    ],
    "memorialData": [
        {
            "title": "3rd day Boxing young boy performance",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/3rd-day-Boxing.mp4",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/3rd-day-Boxing.mp4"
        },
        {
            "title": "3rd day  Football match  performance",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/Football-match-performance.mp4",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/Football-match-performance.mp4"
        },
        {
            "title": "3rd day dance performance",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/dance-1.mp4",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/dance-1.mp4"
        },
        {
            "title": "3rd day Raag tilak with Harmonium  performance",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/Harmonium-performance.mp4",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/Harmonium-performance.mp4"
        },
        {
            "title": "3rd day Authar performance",
            "imageSrc": "https://res.pythiangames.org/assets/images/video/Authar-performance.mp4",
            "videoSrc": "https://res.pythiangames.org/assets/images/video/Authar-performance.mp4"
        }
    ]
}

export default vidData;