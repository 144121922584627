import axiosInstance from '../../assets/utils/api';

const getMembership = async (type) => {
    try {
        const response = await axiosInstance.get(`/api/Membership`,
            {
                headers: {
                    'access-token': 'mebrudship1Ofr1#l+31w',
                    'is-international': type
                }
            });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default getMembership;