import '../../../assets/css/styles.css'
import './EventCategories.css'
import {useNavigate, useParams} from "react-router-dom";
import {signupUrl} from '../../../assets/utils/signup';
import data from '../../../data/event-categories.json';
import {storagePath} from "../../../assets/utils/soragePath";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";

const EventCategories = () => {
    const {event} = useParams();
    const navigate = useNavigate();
    return (<>
        <InnerBanner pageName={data[event].title}/>
        <section className="section light-bg-image1">
            <div className="container">
                <div className="row">
                    {
                        Object.keys(data[event]?.subPages).map((key) => (
                            <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                                <div className="catlist card mb-0"
                                     onClick={() => navigate(`/event-categories/${event}/${key}`)}>
                                    <figure>
                                        <img src={storagePath + data[event]?.subPages[key].img}/>
                                    </figure>
                                    <figcaption>
                                        <p>{data[event]?.subPages[key].name}</p>
                                        <a className="btn-sec">Read More</a>
                                    </figcaption>
                                </div>
                            </div>
                        ))
                    }
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card owneventcard">
                            <img src={storagePath + "static/media/creat-img.46d15ff4d4d9e419cfe5.png"}/>
                            <p className='text-center'>
                                <a href={signupUrl}>Create Your Own Event</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default EventCategories