import '../../../assets/css/styles.css'
import '../EventCategories/EventCategories.css'
import {useParams} from "react-router-dom";
import data from '../../../data/event-categories.json';
import {useEffect, useState} from 'react';
import {signupUrl} from '../../../assets/utils/signup';
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import {storagePath} from "../../../assets/utils/soragePath";

const EventSubCategories = () => {
    const {event, page} = useParams();
    const [oriantation, setOriantation] = useState('potrait');

    useEffect(() => {
        setOriantation(data[event].subPages[page].layout)
    }, [event, page])

    return (
        <>
            <InnerBanner pageName={data[event].subPages[page].name}/>
            <section className='section light-bg-image2'>
                <div className="container">
                    <div className="row">
                        {
                            oriantation == 'landscape' ? (
                                Array.isArray(data[event]?.subPages[page]?.data) ? (
                                    data[event]?.subPages[page]?.data.map((item) => (
                                        <div className="col-12 mb-3 mb-md-4 cardwithIcon col-md-4 col-md-12 full-width">
                                            <div className=" card mb-0">
                                                <figure className="icon-bg">
                                                    <img src={storagePath + item.img}/>
                                                </figure>
                                                <div className="cat-detail">
                                                    <div className="basic-detail">
                                                        <h4 className='fw-bold'>{item.name}</h4>
                                                        <div>
                                                            <p className="head"> Description </p>
                                                            <ul className="description">
                                                                <li>
                                                                    {item?.Description}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <a href={signupUrl}
                                                       className="action-button mt-2 mt-md-4"><span></span><i>Click to
                                                        Register</i></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-12 mb-3 mb-md-4 cardwithIcon col-md-4 col-md-12 full-width">
                                        <div className=" card mb-0">
                                            <figure className="icon-bg">
                                                <img src={storagePath + data[event]?.subPages[page]?.img}/>
                                            </figure>
                                            <div className="cat-detail">
                                                <div className="basic-detail">
                                                    <h4 className='fw-bold'>{data[event]?.subPages[page]?.name}</h4>
                                                    {Object.keys(data[event]?.subPages[page]?.data).map(key => (
                                                        <div>
                                                            <p>{key}</p>
                                                            <ul>
                                                                {data[event]?.subPages[page]?.data[key].map((item2) => (
                                                                    <li>{item2}</li>))}
                                                            </ul>
                                                        </div>)
                                                    )}
                                                    {
                                                        data[event]?.subPages[page]?.Description ?
                                                            <div>
                                                                <p className="head"> Description </p>
                                                                <ul className="description">
                                                                    <li>
                                                                        {data[event]?.subPages[page]?.Description}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                                <a href={signupUrl}
                                                   className="action-button mt-2 mt-md-4"><span></span><i>Click to
                                                    Register</i></a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : (
                                Array.isArray(data[event]?.subPages[page]?.data) ? (
                                    data[event]?.subPages[page]?.data.map((item) => (
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3 mb-md-4 cardwithIcon">
                                            <div className="mb-3 mb-md-4"
                                                 id='mainContainer'>
                                                <div className=" card mb-0">
                                                    <figure className="icon-bg">
                                                        <img src={storagePath + item.img} alt='image'/>
                                                    </figure>
                                                    <div className="cat-detail">
                                                        <div className="basic-detail">
                                                            <h4 className='fw-bold'>{item.name}</h4>
                                                            {Object.keys(item).map((key) => (
                                                                Array.isArray(item[key]) ? (<div>
                                                                    <p className="text-center">{key}</p>
                                                                    <ul>
                                                                        {item[key].map((item2) => (<li>{item2}</li>))}
                                                                    </ul>
                                                                </div>) : ''))}
                                                        </div>
                                                        <a href={signupUrl} className="action-button mt-2 mt-md-4">
                                                            <span></span>
                                                            <i>Click to Register</i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : 'No data found'
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
export default EventSubCategories